/**
 * MoreText
 */
(function () {
    "use strict";

    function MoreText(element, moreLink) {
        if (!element || !moreLink) {
            return;
        }

        moreLink.addEventListener('click', function () {
            element.classList.remove('more-text--collapsed');
        });
    }

    new MoreText(
        document.querySelector('.js-more-text'),
        document.querySelector('.js-more-text__toggle')
    );
}());

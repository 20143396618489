/**
 * Gestisco la spunta "Desidero fattura"
 */
(function () {
    "use strict";

    var master = document.querySelector('.js-need-invoice__master');
    var slave = document.querySelectorAll('.js-need-invoice__slave');

    function toggle() {
        slave.forEach(function (el) {
            el.style.display = master.checked ? 'block' : 'none';
        });
    }

    if (master) {
        master.addEventListener('click', toggle, false);
        toggle();
    }
}());

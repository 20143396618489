/**
 * Add to cart
 */
$(function () {
    "use strict";

    var counter = 0;

    function getBlockCart() {
        // no cache for this because the replace at the end of the process
        return $('.blockcart');
    }

    function startLoading() {
        counter++;
        getBlockCart().addClass('blockcart--loading');
    }

    function endLoading() {
        counter--;

        if (counter === 0) {
            getBlockCart().removeClass('blockcart--loading');
        }
    }

    // add an event on "add to cart" button for start the animation
    $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
        // the event.preventDefault() call is in the core.js of Prestashop

        // prepare
        var $button = $(event.target);
        var $productContainer = $button.closest('.js-product-container');
        var $productCover = $productContainer.find('.js-product-cover');
        var $coverClone = $productCover.clone();
        var productCoverOffset = $productCover.offset();
        var blockCartOffset = getBlockCart().offset();

        // add to cart animation
        $coverClone
            .css({
                position: 'absolute',
                top: productCoverOffset.top,
                left: productCoverOffset.left,
                width: $productCover.width()
            })
            .appendTo('body')
            .animate({
                top: blockCartOffset.top,
                left: blockCartOffset.left,
                width: 50,
                opacity: 1
            }, function () {
                $coverClone.remove();
            });

        startLoading();
    });

    prestashop.on(
        'updateCart',
        function (event) {
            var refreshURL = getBlockCart().data('refresh-url');
            var requestData = {};

            if (!refreshURL) {
                return;
            }

            if (event && event.reason) {
                requestData = {
                    id_product_attribute: event.reason.idProductAttribute,
                    id_product: event.reason.idProduct,
                    action: event.reason.linkAction
                };
            }

            $.post(refreshURL, requestData)
                .then(function (resp) {
                    getBlockCart().replaceWith(
                        $('<div />').html(resp.preview).find('.blockcart')
                    );

                    endLoading();
                })
                .fail(function (resp) {
                    prestashop.emit('handleError', {eventType: 'updateShoppingCart', resp: resp});

                    endLoading();
                });
        }
    );
});

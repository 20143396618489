$(function () {
    var form = $('.js-search-form');
    var container = form.find('.js-search-results-container');
    var results = form.find('.js-search-results');
    var queryInput = form.find('.js-search-query');
    var categorySelect = form.find('.js-search-category');

    var open = false;

    queryInput.on('keyup', function () {
        if (!open) {
            container.slideDown();
            open = true;
        }

        runSearch();
    });

    categorySelect.on('change', function () {
        runSearch();
    });

    $('body')
        .on('click', '.js-search-show-all', function () {
            form.submit();
        })
        .on('click', function (event) {
            if (open && $(event.target).closest('.js-search-form').length === 0) {
                container.slideUp(function () {
                    results.html('');
                    open = false;
                });
            }
        });

    function runSearch() {
        results.html('<p class="search-results-loading" />');

        if (queryInput.val().length < 3) {
            results.html(limit_character);
        } else {
            $.post(
                form.data('ajax-url'),
                {
                    queryString: queryInput.val(),
                    id_Cat: categorySelect.val()
                },
                function (data) {
                    results.html(data);
                }
            );
        }
    }
});

$(function () {
    "use strict";

    /**
     * Element.closest() Polyfill
     *
     * @see https://developer.mozilla.org/it/docs/Web/API/Element/closest
     */
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (s) {
            var el = this;
            if (!document.documentElement.contains(el)) {
                return null;
            }

            do {
                if (el.matches(s)) {
                    return el;
                }

                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);

            return null;
        };
    }

    /**
     * Detecting CSS animation support
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Animations/Detecting_CSS_animation_support
     */
    var supportAnimations = false,
        browserPrefixes = 'Webkit Moz O ms'.split(' '),
        animationstring = 'animation',
        pfx = '',
        elem = document.createElement('div');

    if (elem.style.animationName !== undefined) {
        supportAnimations = true;
    }

    if (supportAnimations === false) {
        for (var i = 0; i < browserPrefixes.length; i++) {
            if (elem.style[browserPrefixes[i] + 'AnimationName'] !== undefined) {
                pfx = browserPrefixes[i];
                animationstring = pfx + 'Animation';
                supportAnimations = true;

                break;
            }
        }
    }

    var animEndEventNames = {
            'WebkitAnimation': 'webkitAnimationEnd',
            'MozAnimation': 'mozAnimationEnd',
            'OAnimation': 'oAnimationEnd',
            'msAnimation': 'MSAnimationEnd',
            'animation': 'animationend'
        },
        animEndEventName = animEndEventNames[animationstring],
        onEndAnimation = function (el, callback) {
            var onEndCallbackFn = function (ev) {
                if (supportAnimations) {
                    if (ev.target != this) return;
                    this.removeEventListener(animEndEventName, onEndCallbackFn);
                }
                if (callback && typeof callback === 'function') {
                    callback.call();
                }
            };
            if (supportAnimations) {
                el.addEventListener(animEndEventName, onEndCallbackFn);
            } else {
                onEndCallbackFn();
            }
        };

    /**
     * Multi-Level Menu
     *
     * @see https://tympanus.net/codrops/2015/11/17/multi-level-menu/
     */
    function Menu(element) {
        this.el = element;

        if (this.el) {
            this._init();
        }
    }

    Menu.prototype._init = function () {
        var self = this;

        this.menus = [].slice.call(this.el.querySelectorAll('.menu__level'));
        this.menusArr = [];
        this.current = 0;

        // console.time('menusArr');

        this.menus.forEach(function (menuEl, pos) {
            var name = '', li;

            // fixme: questo non è molto efficiente, ma siamo cmq nell'ordine dei 15ms per tutto il ciclo...
            if (menuEl.hasAttribute('data-menu')) {
                li = document.querySelector('[data-submenu="' + menuEl.getAttribute('data-menu') + '"]');

                if (li) {
                    name = li.querySelector('.menu__link').innerHTML;
                }
            }

            self.menusArr.push({
                menuEl: menuEl,
                name: name
            });

            if (menuEl.classList.contains('menu__level--current')) {
                self.current = pos;
            }
        });

        // console.timeEnd('menusArr');
        // console.log(self.menusArr);

        this._prepareBreadcrumb();
        this._fixDimensions();
        this._initEvents();
    };

    Menu.prototype._initEvents = function () {
        var self = this;

        self._bindEvents();

        /**
         * Simple debounce
         *
         * @see https://css-tricks.com/snippets/jquery/done-resizing-event/
         */
        var resizeTimer;
        window.addEventListener('resize', function () {
            clearTimeout(resizeTimer);

            resizeTimer = setTimeout(function() {
                self._fixDimensions();
            }, 250);
        });

        document.getElementById('menu-icon').addEventListener("click", function () {
            /**
             * Event loop / Zero delay
             *
             * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/EventLoop
             */
            setTimeout(function () {
                self._fixDimensions();
            });
        });
    };

    Menu.prototype._bindEvents = function () {
        var self = this;

        [].slice.call(this.el.querySelectorAll('.menu__arrow')).forEach(function (arrowEl) {
            arrowEl.addEventListener('click', function (event) {
                event.preventDefault();

                var li = arrowEl.parentElement,
                    submenu = li.getAttribute('data-submenu'),
                    subMenuEl = self.el.querySelector('ul[data-menu="' + submenu + '"]');

                if (subMenuEl) {
                    self._openSubMenu(subMenuEl);
                }
            });
        });
    };

    Menu.prototype._openSubMenu = function (subMenuEl) {
        if (this.isAnimating) {
            return false;
        }

        this.isAnimating = true;

        // current menu slides out
        this._currentMenuOut(false);

        // next menu (submenu) slides in
        this._nextMenuIn(subMenuEl, false);
    };

    Menu.prototype._currentMenuOut = function (isBackNavigation) {
        var currentMenu = this.menusArr[this.current].menuEl;

        // animate out
        currentMenu.classList.add(isBackNavigation ? 'animate-outToRight' : 'animate-outToLeft');
    };

    Menu.prototype._nextMenuIn = function (nextMenuEl, isBackNavigation) {
        var self = this,
            currentMenu = this.menusArr[this.current].menuEl,
            nextMenuIdx = this.menus.indexOf(nextMenuEl);

        // breadcrumb
        if (!isBackNavigation) {
            this._addBreadcrumb(nextMenuIdx);
        }

        // reset current
        this.current = nextMenuIdx;

        // fix dimensions
        this._fixDimensions(nextMenuEl);

        // on end animation
        onEndAnimation(nextMenuEl, function () {
            // reset classes
            currentMenu.classList.remove(isBackNavigation ? 'animate-outToRight' : 'animate-outToLeft');
            nextMenuEl.classList.remove(isBackNavigation ? 'animate-inFromLeft' : 'animate-inFromRight');

            // move .menu__level--current class
            currentMenu.classList.remove('menu__level--current');
            nextMenuEl.classList.add('menu__level--current');

            // we can navigate again!
            self.isAnimating = false;
        });

        // animate in
        nextMenuEl.classList.add(isBackNavigation ? 'animate-inFromLeft' : 'animate-inFromRight');
    };

    Menu.prototype._prepareBreadcrumb = function () {
        this.breadcrumbsCtrl = document.createElement('nav');
        this.breadcrumbsCtrl.className = 'menu__breadcrumbs';
        this.el.insertBefore(this.breadcrumbsCtrl, this.el.firstChild);

        // Home page
        this._addBreadcrumb(0);

        // when we are in a subcategory page
        var self = this;

        function navigate(currentMenuEl) {
            if (!currentMenuEl) {
                return;
            }

            var currentMenuIdx = self.menus.indexOf(currentMenuEl),
                parentMenuId,
                parentMenu;

            if (currentMenuIdx !== 0) {
                parentMenuId = currentMenuEl.getAttribute('data-menu');

                if (parentMenuId) {
                    parentMenu = self.el.querySelector('[data-submenu="' + parentMenuId + '"]');

                    if (parentMenu) {
                        navigate(parentMenu.closest('.menu__level'));
                    }
                }

                self._addBreadcrumb(currentMenuIdx);
            }
        }

        navigate(
            this.el.querySelector('.menu__level--current')
        );
    };

    Menu.prototype._addBreadcrumb = function (idx) {
        var self = this,
            bc = document.createElement('a');

        bc.innerHTML = idx && this.menusArr[idx] ? this.menusArr[idx].name : 'Home';
        this.breadcrumbsCtrl.appendChild(bc);

        bc.addEventListener('click', function (event) {
            event.preventDefault();

            // do nothing if this breadcrumb is the last one in the list of breadcrumbs
            if (!bc.nextSibling || self.isAnimating) {
                return false;
            }

            self.isAnimating = true;

            // remove breadcrumbs that are ahead
            var siblingNode;
            while (siblingNode = bc.nextSibling) {
                self.breadcrumbsCtrl.removeChild(siblingNode);
            }

            // current menu slides out
            self._currentMenuOut(true);

            // next menu slides in
            var nextMenu = self.menusArr[idx].menuEl;
            self._nextMenuIn(nextMenu, true);
        });
    };

    Menu.prototype._fixDimensions = function (menuEl) {
        menuEl = menuEl || document.querySelector('.menu__level--current');

        if (!menuEl) {
            return;
        }

        var height = parseInt(window.getComputedStyle(menuEl).height);
        var top = parseInt(window.getComputedStyle(this.breadcrumbsCtrl).height);

        var wrapper = document.querySelector('.menu__wrap');
        wrapper.style.height = height + 'px';
        wrapper.style.top = top + 'px';

        this.el.style.height = (height + top) + 'px';
    };

    new Menu(document.getElementById('category-top-menu'));
});

/**
 * Manage products list display mode: grid or list
 */
$(function () {
    "use strict";

    var gridButtonSelector = '#ttgrid';
    var listButtonSelector = '#ttlist';
    var productsListSelector = '#js-product-list';

    var gridButton;
    var listButton;
    var productsList;

    // css classes configuration
    var cssClass = {
        list: {
            product: 'product-list col-xs-12',
            image: 'col-xs-5 col-sm-5 col-md-3',
            desc: 'col-xs-7 col-sm-7 col-md-9'
        },
        grid: {
            product: 'product-grid col-lg-3 col-md-4 col-sm-6 col-xs-12',
            image: '',
            desc: ''
        }
    };

    var $body = $(document.body);

    function init() {
        gridButton = $(gridButtonSelector);
        listButton = $(listButtonSelector);
        productsList = $(productsListSelector);

        display(localStorage.getItem('display'));
    }

    function bindEvents() {
        $body
            .on('click', gridButtonSelector, function (event) {
                event.preventDefault();
                display('grid');
            })
            .on('click', listButtonSelector, function (event) {
                event.preventDefault();
                display('list');
            });

        prestashop.on('updateProductList', function () {
            setTimeout(init);
        });
    }

    function display(mode) {
        if (mode === 'list') {
            // grid / list selectors
            gridButton.removeClass('active');
            listButton.addClass('active');

            // product display manipulation
            productsList.find('.js-product-miniature').removeClass(cssClass.grid.product).addClass(cssClass.list.product);
            productsList.find('.ttproduct-image').removeClass(cssClass.grid.image).addClass(cssClass.list.image);
            productsList.find('.ttproduct-desc').removeClass(cssClass.grid.desc).addClass(cssClass.list.desc);

            productsList.find('.ttproducthover').each(function () {
                var el = $(this);

                el.appendTo(
                    el.closest('.js-product-miniature').find(".ttproduct-desc")
                );
            });
        } else {
            // grid / list selectors
            gridButton.addClass('active');
            listButton.removeClass('active');

            // product display manipulation
            productsList.find('.js-product-miniature').removeClass(cssClass.list.product).addClass(cssClass.grid.product);
            productsList.find('.ttproduct-image').removeClass(cssClass.list.image).addClass(cssClass.grid.image);
            productsList.find('.ttproduct-desc').removeClass(cssClass.list.desc).addClass(cssClass.grid.desc);

            productsList.find('.ttproducthover').each(function () {
                var el = $(this);

                el.appendTo(
                    el.closest('.js-product-miniature').find(".ttproduct-image")
                );
            });
        }

        // update local storage for next visits
        localStorage.setItem('display', mode);
    }

    init();
    bindEvents();
});

/*
 * Custom code goes here.
 * A template should always ship with an empty custom.js
 */

/* --------------------------- TmplateTrip JS ------------------------------ */

/* ----------- Start Page-loader ----------- */
	$(window).load(function()
	{
		$(".ttloading-bg").fadeOut("slow");
	})
	/* ----------- End Page-loader ----------- */

$(document).ready(function(){

/* Go to Top JS START */
		if ($('#goToTop').length) {
			var scrollTrigger = 100, // px
				backToTop = function () {
					var scrollTop = $(window).scrollTop();
					if (scrollTop > scrollTrigger) {
						$('#goToTop').addClass('show');
					} else {
						$('#goToTop').removeClass('show');
					}
				};
			backToTop();
			$(window).on('scroll', function () {
				backToTop();
			});
			$('#goToTop').on('click', function (e) {
				e.preventDefault();
				$('html,body').animate({
					scrollTop: 0
				}, 700);
			});
		}
	/* Go to Top JS END */

	/*---------------- Start Search ---------------- */


		$(".ttsearch_button ").click(function() {

		$('.ttsearchtoggle').parent().toggleClass('active');
		$('.ttsearchtoggle').toggle('fast', function() {
		});
		$('#search_query_top').attr('autofocus', 'autofocus').focus();
		});
	/*---------------- End Search ---------------- */

	/* -------------- Start Homepage Tab ------------------- */

	$("#hometab").prepend("<div class='tabs'><ul class='nav nav-tabs'></ul></div>");
	$("#hometab .ttfeatured-products .tab-title").wrap("<li class='nav-item'><a class='nav-link' data-toggle='tab' href='#ttfeatured-content'></a></li>");
	$("#hometab .ttbestseller-products .tab-title").wrap("<li class='nav-item'><a class='nav-link' data-toggle='tab' href='#ttbestseller-content'></a></li>");
	$("#hometab .ttnew-products .tab-title").wrap("<li class='nav-item'><a class='nav-link' data-toggle='tab' href='#ttnew-content'></a></li>");
	$("#hometab .tabs ul.nav-tabs").append($("#hometab > section li.nav-item"));

	$("#hometab > section.ttfeatured-products").wrap("<div class='tab-pane row fade' id='ttfeatured-content'>");
	$("#hometab > section.ttbestseller-products").wrap("<div class='tab-pane row fade' id='ttbestseller-content'>");
	$("#hometab > section.ttnew-products").wrap("<div class='tab-pane row fade' id='ttnew-content'>");
	$("#hometab > .tab-pane").wrapAll("<div class='home-tab-content' id='home-tab-content' />");
	$("#hometab").append($("#hometab > .home-tab-content"));

	$('#hometab .tabs ul.nav-tabs > li:first-child a').addClass('active');
	$('#hometab #home-tab-content .tab-pane:first-child').addClass('in active');

	/* -------------- End Homepage Tab ------------------- */

	/* ---------------- start Templatetrip more menu ----------------------*/
		var max_elem = 12;
		var leftmenu = $('#left-column .menu ul#top-menu > li');
		if ( leftmenu.length > max_elem ) {
			$('#left-column .menu ul#top-menu').append('<li class="more"><div class="more-menu"><span class="categories">More<i class="material-icons">&#xE313;</i></span></div></li>');
		}

		$('#left-column .menu ul#top-menu .more-menu').click(function() {
			if ($(this).hasClass('active')) {
				leftmenu.each(function(j) {
					if ( j >= max_elem ) {
						$(this).slideUp(200);
					}
				});
				$(this).removeClass('active');
				//$(this).children('div').css('display', 'block');
				$('.more-menu').html('<span class="categories">More<i class="material-icons">&#xE313;</i></span>');
			} else {
				leftmenu.each(function(j) {
					if ( j >= max_elem  ) {
						$(this).slideDown(200);
					}
				});
				$(this).addClass('active');
				$('.more-menu').html('<span class="categories">Less<i class="material-icons">&#xE316;</i></span>');
			}
		});

		leftmenu.each(function(j) {
			if ( j >= max_elem ) {
				$(this).css('display', 'none');
			}
		});
	/* ---------------- End Templatetrip more menu ----------------------*/


	/* ------------ Start Add Product Bootsrap class JS --------------- */

	colsCarousel = $('#right-column, #left-column').length;
	if (colsCarousel == 2) {
		ci=4;
	} else if (colsCarousel == 1) {
		ci=3;
	} else {
		ci=4;
	}


		// var cols_count = $('#right-column, #left-column').length;
		// var products = $('#content .products .product-miniature, #content-wrapper .products .product-miniature')
         //    .removeClass('col-lg-3 col-lg-4 col-md-3 col-md-4 col-sm-6 col-xs-12');
        //
		// if (cols_count === 2) {
		// 	products.addClass('js-product-miniature product-grid col-lg-3 col-md-4 col-sm-6 col-xs-12');
		// } else if (cols_count === 1) {
		// 	products.addClass('js-product-miniature product-grid col-lg-4 col-md-4 col-sm-6 col-xs-12');
		// } else {
		// 	products.addClass('js-product-miniature product-grid col-lg-3 col-md-3 col-sm-6 col-xs-12');
		// }

	/* ------------ End Add Product Bootsrap class JS --------------- */



	/* ----------- Start Carousel For FeatureProduct / BestsellerProduct / NewProduct / SpecialProduct / ViewProduct / CategoryProduct ----------- */

	$(".ttfeatured-products .products ,.ttbestseller-products .products, .ttnew-products .products, .ttspecial-products .products, .crossselling-product .products, .view-product .products, .category-products .products, .product-accessories .products").owlCarousel({
		navigation:true,
		navigationText: [
			"<i class='material-icons'>&#xE5CB;</i>",
			"<i class='material-icons'>&#xE5CC;</i>"],
		items: ci, //10 items above 1000px browser width
		itemsDesktop : [1200,2],
		itemsDesktopSmall : [991,3],
		itemsTablet: [767,2],
		itemsMobile : [480,1]
	});

	/* ----------- End Carousel For FeatureProduct / BestsellerProduct / NewProduct / SpecialProduct / ViewProduct / CategoryProduct ----------- */





	/* ----------- Start Carousel For Productpage Thumbs ----------- */

		$("#ttproduct-thumbs").owlCarousel({
		navigation:true,
		navigationText: [
			"<i class='material-icons'>&#xE5CB;</i>",
			"<i class='material-icons'>&#xE5CC;</i>"],
		items: 4, //10 items above 1000px browser width
		itemsDesktop : [1200,4],
		itemsDesktopSmall : [991,3],
		itemsTablet: [767,2],
		itemsMobile : [480,1]
	});

	/* ----------- End Carousel Productpage Thumbs ----------- */


		/* -----------Start carousel For TT- brand logo ----------- */
	 var ttbrandlogo = $("#ttbrandlogo-carousel");
		  ttbrandlogo.owlCarousel({
			navigation:true,
			navigationText: [
					"<i class='material-icons'>&#xE5CB;</i>",
					"<i class='material-icons'>&#xE5CC;</i>"],
		  autoPlay : true,
			 items :5, //10 items above 1000px browser width
			 itemsDesktop : [1200,5],
			 itemsDesktopSmall : [991,4],
			 itemsTablet: [767,3],
			 itemsMobile : [480,2]
		  });

	/* -----------End carousel For TT brand logo ----------- */


	/* -----------Start carousel For TT- cms Testimonial ----------- */

	 var tttestimonial = $("#tttestimonial-carousel");
      tttestimonial.owlCarousel({
  		 navigation: true,
		 navigationText: [
			"<i class='material-icons'>&#xE5CB;</i>",
			"<i class='material-icons'>&#xE5CC;</i>"],
		 autoPlay:true,
     	 items : 1, //10 items above 1000px browser width
     	 itemsDesktop : [1200,1],
     	 itemsDesktopSmall : [991,1],
     	 itemsTablet: [767,1],
     	 itemsMobile : [480,1]
      });




/* -----------End carousel For TT- cms Testimonial ----------- */

/* ----------- Start Templatetrip user info ----------- */

$('.ttuserheading').click(function(event){
			$(this).toggleClass('active');
			event.stopPropagation();
			$(".user-info").slideToggle("fast");
		});
		$(".user-info").on("click", function (event) {
			event.stopPropagation();
		});
/* ----------- End Templatetrip user info ----------- */


 /* ----------- Start Templatetrip AddToCart Button ----------- */

$( ".tt-button-container .add-to-cart" ).mousedown(function() {
  var form_className = $(this).parent().attr('class');
  $(this).parent().attr('id',form_className);

  var hidden_page_className = $(this).parent().find('.product-quantity .product_page_product_id').attr('class');
  $(this).parent().find('.product-quantity .product_page_product_id').attr('id',hidden_page_className);

  var customization_className = $(this).parent().find('.product-quantity .product_customization_id').attr('class');
  $(this).parent().find('.product-quantity .product_customization_id').attr('id',customization_className);

  var quantity_className = $(this).parent().find('.product-quantity .quantity_wanted').attr('class');
  $(this).parent().find('.product-quantity .quantity_wanted').attr('id',quantity_className);
});

$( ".tt-button-container .add-to-cart" ).mouseup(function() {
  $(this).parent().removeAttr('id');
  $(this).parent().find('.product-quantity > input').removeAttr('id');
});

$('.header-top .position-static #ttcmstopbanners').prependTo('#wrapper');
$('#_mobile_logo').prependTo('.header-top .position-static .row');
$('#_mobile_cart').prependTo('.header-top .position-static .row');


/* ----------- End Templatetrip AddToCart Button ----------- */
});


function header() {
 if (jQuery(window).width() > 1200){
     if (jQuery(this).scrollTop() > 400)
        {
            jQuery('.header-top').addClass("fixed");

    	}else{
      	 jQuery('.header-top').removeClass("fixed");
      	}
    } else {
      jQuery('.header-top').removeClass("fixed");
      }
}

$(document).ready(function(){header();});
jQuery(window).resize(function() {header();});
jQuery(window).scroll(function() {header();});



/* End Homepage header JS */

/*--------- Start js for social -------------*/
function responsivesocial(status)
{
if ($(document).width() <= 991)
{
$('.bottom-footer  > .container .block-social').appendTo('#footer .footer-container > .container > .row');
}
else
{
$('#footer .footer-container .block-social').prependTo('.bottom-footer > .container');
}
}

$(document).ready(function(){responsivesocial();});
$(window).resize(function(){responsivesocial();});
/*--------- End js for social -------------*/



